<template>
  <div class="moondayStatistics">
    <h2 v-if="moondayFilterTarget">
      <span v-html="moondayFilterTarget.icon"></span
      >{{ moondayFilterTarget.cn }}日心情統計
    </h2>
    <h2 v-else>
      <img src="@/assets/月亮日記/月亮icon.svg" alt="" />
      所有月亮日心情統計
    </h2>
    <div id="moondayStatisticsChart"></div>
    <div class="moondayStatisticsIcon">
      <img
        :src="emotion.normal_icon"
        v-for="emotion in emotionList"
        :key="emotion.id"
      />
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import emotionList from "@/js/emotionList";
export default {
  props: ["statistics"],
  data() {
    return {
      emotionList,
    };
  },
  computed: {
    moondayFilterTarget() {
      return this.$store.getters.moondayFilterTarget;
    },
  },
  mounted() {
    let isMobile = window.screen.width < 430;
    let myChart = echarts.init(
      document.getElementById("moondayStatisticsChart")
    );
    let HappyCount = this.statistics["Happy"] || 0;
    let CalmCount = this.statistics["Calm"] || 0;
    let FrightenedCount = this.statistics["Frightened"] || 0;
    let SadCount = this.statistics["Sad"] || 0;
    let AngryCount = this.statistics["Angry"] || 0;

    myChart.setOption({
      xAxis: {
        type: "category",
        data: ["開心", "平靜", "驚嚇", "難過", "生氣"],
      },
      yAxis: {
        type: "value",
        minInterval: 1,
      },
      series: [
        {
          data: [
            {
              value: HappyCount,
              itemStyle: {
                color: "#F7D060",
              },
            },
            {
              value: CalmCount,
              itemStyle: {
                color: "#5AAC80",
              },
            },
            {
              value: FrightenedCount,
              itemStyle: {
                color: "#A48080",
              },
            },
            {
              value: SadCount,
              itemStyle: {
                color: "#6499E9",
              },
            },
            {
              value: AngryCount,
              itemStyle: {
                color: "#E76161",
              },
            },
          ],
          type: "bar",
          legendHoverLink: false,
          barMaxWidth: isMobile ? 16 : 28,
          padding: 5,
        },
      ],
    });
  },
};
</script>

<style lang="scss">
@import "@/scss/_mixin.scss";

.moondayStatistics {
  max-width: 600px;
  height: 410px;
  margin: 36px auto 0;
  border-radius: 20px;
  background: #f0f2f4;
  box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
    4px 4px 25px rgba(0, 0, 0, 0.3);
  padding: 18px;
  @include mobile {
    padding: 16px;
    height: 316px;
  }
  h2 {
    color: #ab956c;
    font-size: 20px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0.8px;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: flex;
      align-items: center;
      .iconfont {
        font-size: 1.8em;
        line-height: 22px;
      }
    }
  }
  #moondayStatisticsChart {
    width: 560px;
    height: 338px;
    @include mobile {
      width: calc(100vw - 46px - 32px);
      height: 260px;
    }
  }
  .moondayStatisticsIcon {
    display: flex;
    justify-content: space-around;
    width: calc(560px - 36px - 80px);
    margin: -40px auto 0;
    @include mobile {
      width: calc(100vw - 46px - 32px - 60px);
    }
    img {
      width: 20px;
      height: 20px;
      @include mobile {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
