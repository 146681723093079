<template>
  <FixedPopupVue>
    <div class="moondayExport">
      <div class="moondayExport__title">下載月亮日記</div>
      <div class="moondayExport__timeRange">
        <div class="item__input" @click.stop="startDateModal = true">
          <span>{{ startDate || "起始日" }}</span>
          <v-dialog
            ref="dialog"
            v-model.trim="startDateModal"
            width="290px"
            persistent
          >
            <v-date-picker
              class="picker date-picker"
              v-model.trim="startDate"
              :first-day-of-week="0"
              locale="zh-cn"
              scrollable
              header-color="rgba(217, 217, 217, 0.25)"
              color="primary"
              max="2099-12-31"
              min="0676-01-01"
            >
              <v-spacer></v-spacer>
              <v-btn text color="error" @click="startDateModal = false"
                >確認</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </div>
        <div class="moondayExport__timeRange__split">至</div>
        <div class="item__input" @click.stop="endDateModal = true">
          <span>{{ endDate || "結束日" }}</span>
          <v-dialog
            ref="dialog"
            v-model.trim="endDateModal"
            width="290px"
            persistent
          >
            <v-date-picker
              class="picker date-picker"
              v-model.trim="endDate"
              :first-day-of-week="0"
              locale="zh-cn"
              scrollable
              header-color="rgba(217, 217, 217, 0.25)"
              color="primary"
              max="2099-12-31"
              min="0676-01-01"
            >
              <v-spacer></v-spacer>
              <v-btn text color="error" @click="endDateModal = false"
                >確認</v-btn
              >
            </v-date-picker>
          </v-dialog>
        </div>
      </div>
      <div class="export__btn">
        <div class="export__btn__cancel" @click="$emit('close')">取消</div>
        <div class="export__btn__download" @click="toExport">下載</div>
      </div>
    </div>
  </FixedPopupVue>
</template>

<script>
import FixedPopupVue from "../Fixed_popup.vue";
export default {
  components: {
    FixedPopupVue,
  },
  data() {
    return {
      startDate: "",
      startDateModal: false,
      endDate: "",
      endDateModal: false,
    };
  },
  methods: {
    async toExport() {
      if (!this.startDate || !this.endDate) {
        return alert("請輸入日期區間");
      }
      let res = await this.$API.POST_DownloadMoonDay({
        StartDate: this.startDate,
        EndDate: this.endDate,
      });
      const data = res.data;
      const url = window.URL.createObjectURL(new Blob([data]), {
        type: "application/vnd.ms-excel;charset=utf-8",
      });
      const link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.setAttribute("download", "月亮日記.xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";

.moondayExport {
  margin: 0 auto;
  padding: 36px 49px 40px;
  width: 520px;
  height: 260px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 50px;
  background: var(--vi-white, #f0f2f4);
  box-shadow: 4px 4px 25px 0px rgba(0, 0, 0, 0.3), -4px -4px 10px 0px #fff,
    -4px -4px 16px 0px #fff;

  &__title {
    color: #17445c;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 1.92px;
    margin-bottom: 32px;
  }
  &__timeRange {
    display: flex;
    align-items: center;
    .item__input {
      width: 189px;
      height: 32px;
      border-radius: 20px;
      background: #f0f2f4;
      box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.15) inset,
        2px 2px 4px 0px #fff;
      font-size: 14px;
      font-weight: 500;
      line-height: 26px;
      color: #ab956c;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__split {
      margin: 0 12px;
    }
  }
  .export__btn {
    margin-top: 40px;
    display: flex;

    &__cancel {
      @include btn-default(138px, 44px, 20px);
      font-size: 16px;
      line-height: 30px;
      transition: 0.2s;
      color: #9b9b9b;
      background: transparent;
      border: 1px solid #9b9b9b;
      &:hover {
        color: #9b9b9b;
        background: transparent;
        border: 1px solid #9b9b9b;
      }
      &.disable {
        pointer-events: none;
        opacity: 0.4;
      }
      @include mobile {
        height: 40px;
        font-size: 14px;
        line-height: 21px;
      }
    }
    &__download {
      @include btn-default(138px, 44px, 20px);
      font-size: 16px;
      line-height: 30px;
      border: 2px solid rgba(121, 141, 165, 0.3);
      text-align: center;
      color: #ffffff;
      margin-left: 16px;

      @include mobile {
        height: 40px;
        font-size: 14px;
        line-height: 21px;
      }
      &.disable {
        background-color: #9b9b9b;
        color: #fff;
        font-weight: 400;
        pointer-events: none;
      }
    }
  }
}
</style>
